html {
  overflow: scroll;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

iframe {
  display: none;
}

.swiper {
  left: 125px;
  overflow: visible;
}